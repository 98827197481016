.btnMenu {
  position: fixed;
  right: 7%;
  top: 7vh;
  cursor: pointer;
  z-index: 5;
}
.sidenav {
    height: 100vh;
    width: 50vw;
    position: fixed;
    z-index: 1;
    top: 0;
    background-color: #1F1F1F;
    overflow-x: hidden;
    right: -100%;
    display: none;
    align-items: center;
}
.sidenav.active {
  right: 0;
  transition: 0.5s;
  display: flex
}
.nav-menu-items {
  list-style-type: none;
  padding: 0;
  width: 100%;
}
.navLinks {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #fff;
  display: block;
  transition: 0.3s;
  font-family: Abula Pro Regular;
  width: 100%;
  padding-left: 15%;
  line-height: 1.5;
}
.navLinks:hover {
    text-decoration: line-through;
}

@media screen and (max-width: 1080px) {
  .sidenav {
    width: 100vw;
  }
}