.Err-container {
    background-color: #E6344C;
    width: 100vw;
    height: 100vh;
}
.Err-container h1 {
    font-family: Abula Pro Bolt;
    color: white;
    font-size: 6.8vw;
    position: fixed;
    top: 25%;
    left: 20vw;
    line-height: 1.1;
}
.Err-otaznik {
    position: fixed;
    right: 20vw;
    top: 25vh;
    width: 12vw;
}
.Err-homeButton {
    text-decoration: none;
    color: white;
    display: flex;
    justify-content: space-between;
    width: 220px;
    position: fixed;
    bottom: 15vh;
    font-size: 25px;
    font-family: Abula Pro Bolt;
    left: 20vw;
    transform: translateX(0);
}
.Err-homeButton:hover > #Err_btn_headline  {
    transform: translateX(-75px);
    transition: 500ms;
}
@media screen and (max-width: 1080px) {
    .Err-otaznik {
        display: none;
    }
    .Err-container h1 {
        left: 10vw;
        font-size: 55px;
    }
    .Err-homeButton {
        left: 10vw;
        width: 150px;
        font-size: 20px;
    }
    .Err-homeButton:hover > #Err_btn_headline  {
        transform: translateX(-0px);
        transition: 500ms;
    }
}