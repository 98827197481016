.PC_main {
    display: flex;
    width: 80vw;
    flex-direction: column;
    align-items: center;
    position: relative;
    bottom: 10%;
}
.PC_comp {
    font-family: Abula Pro Bolt;
    width: 80%;
    border-right: 0;
    border-bottom: 1px solid white;
    min-height: 10%;
    padding-bottom: 5%;
    padding-top: 5%;
}
.PC_comp p {
    font-family: Abula Pro Regular;
}
.PC_comp h1 {
    float: left;
    margin-right: 15px;
    line-height: 1;
}
.PC_comp:last-child {
    border-bottom: none;
}