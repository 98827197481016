@font-face {
    font-family: Abula Pro Bolt;
    src: url(source/Serpentype\ -\ Albula\ Pro\ Bold.otf);
}
@font-face {
    font-family: Abula Pro Regular;
    src: url(source/Serpentype\ -\ Albula\ Pro\ Regular.otf);
}
@font-face {
    font-family: Abula Pro Light;
    src: url(source/Serpentype\ -\ Albula\ Pro\ Light.otf);
}
html {
	transition: opacity 2s;
}
a {
    text-decoration: none;
}
body { 
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
    margin: 0;
} 

@keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    100% { 
        opacity: 1; 
     } 
} 

h1, h2, h3 {
    margin: 0;
}