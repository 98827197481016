#S-Nav_Container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 15vh;
    left: 10%;
    z-index: 0;
}
#S-Nav_Container div {
    margin-bottom: 30px;
}
.S-Nav_Links {
    text-decoration: none;
    color: white;
    font-family: Abula Pro Regular;
    font-size: 18px;
    line-height: 1.2;
}
.S-Nav_Links:hover {
    font-family: Abula Pro Bolt;
    text-decoration: line-through;
}
@media screen and (max-height: 740px) {
    #S-Nav_Container {
        top: 18vh;
    }
}