.SC_grid {
    display: grid;
    position: absolute;
    grid-template-columns: auto auto auto;
    gap: 2vh 1vw;
    width: 77vw;
}
.SC_mainComp {
    font-family: Abula Pro Bolt;
    font-size: 1.7vw;
    width: 25vw;
    height: 30vh;
    display: flex;
    justify-content: center;
    color: white;
}
.SC_mainComp:hover {
    background-color: #FF3B55;
    text-decoration: line-through;
}
.SC_img {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}
.SC_img img {
    margin-right: 10%;
    width: 53px;
    height: 56px;
}
.SC_corner {
    position: relative;
    right: 0;
    bottom: 0;
    align-self: flex-end;
}
@media screen and (max-width: 1080px) {
    .SC_mainComp {
        width: 40vw;
        height: 25vh;
        font-size: 15px;
    }
    .SC_grid {
        grid-template-columns: auto auto;
        width: 90vw;
        height: 80vh;
        padding-bottom: 15vh;
    }
    .SC_img img {
        width: 33px;
        height: 36px;
        margin-bottom: 15px;
        margin-right: 40px;
    }
    .SC_img {
        flex-direction: column;
    }
    .SC_corner {
        position: relative;
        right: 0;
        bottom: 0;
        align-self: flex-end;
        width: 20px;
    }
}