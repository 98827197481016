.Error h1 {
    font-family: Abula Pro Regular;
    position: fixed;
    top: 20vh;
    left: 20vw;
    color: #D7D7D7;
    width: 60vw;
}
.ErrContainer {
    position: fixed;
    left: 20vw;
    top: 35vh;
}
.ErrSubmit {
    font-family: Abula Pro Bolt;
    position: fixed;
    left: 20vw;
    bottom: 15vh;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 180px;
    transform: translateX(0);
}
#email_form {
    display: flex;
    align-items: center;
    border-bottom: 2px solid black;
    width: 170px;
    margin-bottom: 20px;
    padding: 5px;
}
#email_form input {
    border: none;
    margin-left: 10px;
}
textarea {
    resize: none;
    width: 30vw;
    height: 20vh;
    font-family: Abula Pro Light;
    border: 1px solid black;
    padding: 5px;
}
.ErrSubmit:hover > h2  {
    transform: translateX(65px);
    transition: 500ms;
}
.Input_no_outline:focus {
    outline: none;
}
@media screen and (max-width: 1080px) {
    .Error h1 {
        font-size: 25px;
        left: 10vw;
        width: 80vw;
    }
    .ErrContainer {
        left: 10%;
        top: 45vh;
    }
    textarea {
        width: 77vw;
    }
    #email_form {
        width: 70%;
    }
    .Input_no_outline {
        width: 100%;
    }
    .ErrSubmit {
        left: 60%;
        font-size: 15px;
        width: 150px;
        bottom: 10vh;
    }
}