.Services_main_photo {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}
#Services_logo {
    position: absolute;
    top: 80px;
    left: 10%;

}
#S_Corner {
    position: absolute;
    right: 7%;
    bottom: 10%;
}
.Services_info {
    height: 500px;
    background-color: #E6344C;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    color: white;
    margin-top: -5px;
}
.Services_info-format {
    display: flex;
}
.Services_info_item {
    margin: 2%;
    width: 25vw;
}
.Services_info_item h3 {
    font-family: Abula Pro Bolt;
}
.Services_info_item p {
    font-family: Abula Pro Regular;
}
.S_Footer {
    width: 99vw;
    background-color: #009EE2;
    height: 50vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.S_btn_link1 {
    display: flex;
    justify-content: space-between;
    width: 220px;
    font-family: Abula Pro Bolt;
    color: white;
    position: absolute;
    right: 7%;
}
.S_btn_link2 {
    display: flex;
    justify-content: space-between;
    width: 220px;
    font-family: Abula Pro Bolt;
    color: white;
    margin: 7%;
}
.S_btn_headline {
    transform: translateX(0);
}
.S_btn_animate:hover > .S_btn_headline  {
    transform: translateX(70px);
    transition: 500ms;
}
@media screen and (max-width: 1080px) {
    .Services_info {
        height: 650px;
    }
    .Services_info-format {
        flex-direction: column;
    }
    .Services_info_item {
        width: 80vw;
    }
    .S_Footer {
        width: 100%;
    }
    .S_btn_animate:hover > .S_btn_headline  {
        transform: translateX(0);
        transition: 500ms;
    }
    .S_btn_link1 {
        display: none;
    }
    .S_btn_link2 {
        width: 180px;
    }
}
@media screen and (max-width: 450px) {
    .Services_info {
        height: 750px;
    }
}
/* Odpadní stylování buttonu kontakt */

@media screen and (max-height: 1620px) {
    .S_btn_link1 {
        top: 126%;
    }
}
@media screen and (max-height: 1448px) {
    .S_btn_link1 {
        top: 128%;
    }
}
@media screen and (max-height: 1328px) {
    .S_btn_link1 {
        top: 132%;
    }
}
@media screen and (max-height: 1212px) {
    .S_btn_link1 {
        top: 134%;
    }
}
@media screen and (max-height: 1080px) {
    .S_btn_link1 {
        top: 141%;
    }
}
@media screen and (max-height: 890px) {
    .S_btn_link1 {
        top: 148%;
    }
}
@media screen and (max-height: 780px) {
    .S_btn_link1 {
        top: 158%;
    }
}

