.btnKontakt {
    font-family: Abula Pro Bolt;
    position: absolute;
    right: 10vw;
    bottom: 10vh;
    cursor: pointer;
}
.btnLink {
    display: flex;
    justify-content: space-between;
    color: white;
    text-decoration: none;
    width: 220px;
}
.btnLink  {
    transform: translateX(0);
}
.btnLink:hover > #Btn_headline  {
    transform: translateX(70px);
    transition: 500ms;
}
@media screen and (max-width: 1080px) {
    .btnLink {
        width: 140px;
        font-size: 10px;
    }
    .btnLink:hover > #Btn_headline  {
        transform: translateX(0);
        transition: 500ms;
    }
}
@media screen and (max-width: 650px) {
    .btnKontakt {
        bottom: 1%;
    }
}
@media screen and (max-height: 530px) {
    .btnKontakt {
        bottom: 0.5%;
    }
}