.PC_main1 {
    display: flex;
    position: absolute;
    bottom: 42vh;
}
.PC_comp1 {
    width: 15vw;
    min-height: 30vh;
    border-right: 1px solid white;
    padding: 2%;
    font-family: Abula Pro Bolt;
}
.PC_comp1:last-child {
    border-right: none;
}
.PC_comp1 p {
    font-family: Abula Pro Regular;
}
/*
@media screen and (max-width: 1080px) {
    .PC_main {
        flex-direction: column;
        bottom: 5%;
        font-size: 11px;
        align-items: center;
    }
    .PC_comp {
        width: 80%;
        border-right: 0;
        border-bottom: 1px solid white;
        min-height: 10%;
        padding-top: 3%;
    }
    .PC_comp p {
        margin-top: 1%;
        margin-bottom: 1%;
    }
    .PC_comp h1 {
        float: left;
        margin-right: 15px;
        line-height: 1;
    }
    .PC_comp:last-child {
        border-bottom: none;
    }
}
@media screen and (max-height: 650px) {
    .PC_comp {
        padding-bottom: 0;
        padding-top: 5px;
    }
    .PC_main {
        bottom: 4%;
    }
}
*/