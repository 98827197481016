.firstSection {
    height: 100vh;
    width: 100vw;
    background-color: #000000;
    color: #FFFFFF;

}
.headline {
    font-size: 7vw;
    font-family: Abula Pro Bolt;
    position: absolute;
    top: 37vh;
    left: 10%;
    width: 40%;
    line-height: 1.1;
}
#img_otisk {
    position: absolute;
    height: 100vh;
    width: 40vw;
    right: 5%;
    top: 0;
}
.Qmenu_center {
    position: absolute;
    height: 100vh;
    width: 50vw;
    left: 50%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#corner {
    position: relative;
    top: 60vh;
    left: 47vw;
}
@media screen and (max-width: 1080px) {
    #img_otisk {
        width: 100vw;
        right: 0;
    }
    .headline {
        left: 10%;
        top: 25vh;
        font-size: 10vw;
    }
    #corner {
        position: relative;
        top: 80vh;
        left: 90vw;
    }
    .Qmenu_center {
        left: 18%;
        top: 20vh;
    }
}
@media screen and (max-width: 500px) {
    .headline {
        font-size: 15vw;
    }
    #corner {
        left: 85vw;
    }
}