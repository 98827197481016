.S_Logo-container {
    display: flex;
}
.S_Logo-container img {
    width: 50vw;
    display: flex;
}
.S_Logo-container-all img {
    width: 99.1vw;
    margin-top: -0.2%;
    margin-bottom: -0.5%;
    position: relative;
}
.right {
    float: right;
}

@media screen and (max-width: 1080px) {
    .S_Logo-container {
        float: left;
    }
    .S_Logo-container img {
        width: 100vw;
    }
    .S_Logo-container-all img {
        width: 100vw;
        margin-top: -0.2%;
        margin-bottom: -0.5%;
    }
    .right {
        float: left;
    }
    
}