.KontaktPage {
    background-color: #009EE2;
    width: 100vw;
    height: 100vh;
    font-family: Abula Pro Regular;
    color: white;
}
#KontaktLogo {
    position: absolute;
    top: 7vh;
    left: 8vw;
}
#KontaktPage_nadpis {
    font-family: Abula Pro Bolt;
    position: absolute;
    top: 20vh;
    left: 20vw;
    font-size: 100px;
}
.bold {
    font-family: Abula Pro Bolt;
}
.KontaktContainer1 {
    position: absolute;
    top: 40vh;
    left: 20vw;
    line-height: 1.8;
}
.KontaktContainer2 {
    position: absolute;
    top: 60vh;
    left: 20vw;
    width: 35vw;
}
.KontaktContainer3 {
    position: absolute;
    top: 60vh;
    right: 20vw;
    width: 20vw;
}

.SM_icon {
    position: absolute;
    right: 10vw;
    bottom: 6vh;
    transition: transform .7s ease-in-out;
}
.SM_icon:hover {
    transform: rotate(360deg);
}
#SM_icon_IG {
    right: 15vw;
}
@media screen and (max-width: 1080px) {
    .KontaktPage {
        height: 130vh;
    }
    #KontaktPage_nadpis {
        font-size: 50px;
        left: 10vw;
    }
    .KontaktContainer1 {
        left: 10vw;
        top: 30vh;
    }
    .KontaktContainer2 {
        top: 50vh;
        left: 10vw;
        width: 80vw;
    }
    .KontaktContainer3 {
        top: 90vh;
        left: 10vw;
        width: 80vw;
    }
    .SM_icon {
        right: 10vw;
        top: 120vh;
        width: 25px;
        transition: transform .7s ease-in-out;
    }
    #SM_icon_IG {
        right: 20vw;
    }
    .S_Menu_Local {
        top: 8vh;
        right: 11vw;
    }

}
@media screen and (max-width: 412px) {
    .KontaktPage {
        height: 150vh;
    }
    .KontaktContainer1 {
        top: 35vh;
    }
    .KontaktContainer2 {
        top: 60vh;
    }
    .KontaktContainer3 {
        top: 110vh;
    }
    #SM_icon_IG {
        right: 25vw;
    }
    .SM_icon {
        top: 140vh;
    }
}